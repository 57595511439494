body {
    font-family: "SF Pro Display";

    @include tablet-1000 {
        overflow: auto;
    }
}

html {
    @include tablet-1000 {
        overflow: auto;
    }
}

.section-main {
    padding-left: 393px;
    padding-top: toVh(205);
    padding-right: vwd(100);
    padding-bottom: toVh(50);
    height: 100%;
    transition: 1s ease;
    opacity: 0;
    z-index: 12;

    @include tablet-1000 {
        opacity: 1 !important;
    }

    @include tablet {
        padding-top: toVh(170);
        padding-left: 160px;
    }

    @include tablet-1000 {
        height: auto;
        padding-top: 120px;
        padding-left: 70px;
    }

    @include mobile-620 {
        padding-left: 20px;
        padding-top: 70px;
    }
}

.container {
    &-main {
        width: 100%;
        margin: 0 auto;
        height: 100%;

        @include tablet-1000 {
            overflow: visible;
        }
    }

    box-sizing: border-box;
    width: 95%;
    max-width: 1530px;
    // max-width: calc(100% - 30px * 2);
    margin: 0 auto;

    &-fluid {
        max-width: calc(100% - 200px);
        margin: auto;

        @include mobile {
            max-width: calc(100% - 50px);
        }
    }

    @include tablet {
        width: 95%;
        max-width: 1530px;
    }

    @include tablet-1200 {
        width: 738px;
        max-width: 100%;
    }

    @include mobile {
        width: 100%;
        padding: 0 15px;
    }
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: #000;
}

::-webkit-scrollbar-thumb {
    background-color: #E9CF49;
    border-radius: 8px;
    border: 1px solid #000;

}