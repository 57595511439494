.section {
    &-home {
        background-image: url("../img/bg/home.jpg");
        background-size: cover;
        background-position: 100% 22%;
        background-repeat: no-repeat;
    }

    &-about {
        background-image: url("../img/bg/about.jpg");
        background-size: cover;
        background-position: 100% 22%;
        background-repeat: no-repeat;
    }

    &-cases {
        background-image: url("../img/bg/partners.jpg");
        background-size: cover;
        background-position: 100% 22%;
        background-repeat: no-repeat;
    }

    &-contacts {
        background-image: url("../img/bg/contacts.jpg");
        background-size: cover;
        background-position: 100% 22%;
        background-repeat: no-repeat;
    }

    &-services {
        background-image: url("../img/bg/services.jpg");
        background-size: cover;
        background-position: 100% 22%;
        background-repeat: no-repeat;
    }

    &-partners {
        background-image: url("../img/bg/partners.jpg");
        background-size: cover;
        background-position: 100% 22%;
        background-repeat: no-repeat;
    }

    &-testimonials {
        background-image: url("../img/bg/testimonials.jpg");
        background-size: cover;
        background-position: 100% 22%;
        background-repeat: no-repeat;
    }

    &-awards {
        background-image: url("../img/bg/awards.jpg");
        background-size: cover;
        background-position: 100% 22%;
        background-repeat: no-repeat;
    }

    &-blog {
        background-image: url("../img/bg/blog.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}