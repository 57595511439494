.nav-widget {
    position: fixed;
    z-index: 20;
    padding-left: 105px;
    padding-top: toVh(205);
    height: 100vh;
    padding-bottom: 20px;

    @include tablet {
        padding-top: toVh(170);
        padding-left: 65px;
    }

    @include tablet-1000 {
        display: none;
    }

    &__wrapper {
        height: 95vh;
        display: flex;
        flex-direction: column;
    }

    &__line {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 80%;
        padding-top: 70px;
        padding-bottom: 20px;

        @media (max-height: 850px) {
            height: 80%;
        }

        &-transparent {
            height: 30%;
            background-color: transparent;
            width: 2px;
        }

        &-gold {
            position: absolute;
            height: 20%;
            background-color: #E9CF49;
            width: 2px;
            transition: height .3s ease;
            top: 0;
        }

        &-white {
            height: 70%;
            background-color: $white-main;
            width: 2px;
            margin-bottom: 20px;

        }
    }

    &__name-page {
        transform: rotate(-90deg) translateX(50%);
        position: absolute;
        top: 160px;
        transform-origin: center;
        text-transform: uppercase;
        width: 160px;
        text-align: right;
        font-size: 18px;
        line-height: 130%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #F3F3F3;

        @media (max-height: 850px) {
            font-size: 16px;
        }
    }

    &__name-number {
        transform: rotate(-90deg);
        display: flex;
        justify-content: flex-end;
        position: absolute;
        bottom: 0;
    }
}