.cases {
    position: relative;
    overflow: hidden;

    &__container {
        display: flex;
        position: relative;
        overflow: hidden;
    }

    &__row {
        display: flex;
        flex-wrap: nowrap;
        flex: 0 0 auto;
        animation: runningLine 10s linear infinite;
        animation-play-state: running;

        @include tablet-1000 {
            animation-play-state: running;
        }

        &:hover {
            animation-play-state: paused;
        }

        &-wrapper {
            display: flex;
            flex-wrap: nowrap;
            flex: 0 0 auto;
        }
    }

    @keyframes runningLine {

        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-33.3%);
        }
    }

    @keyframes runningLineWrap {

        0% {
            transform: translateX(-33.3%);
        }

        100% {
            transform: translateX(0);
        }
    }

    &__card {
        width: 427px;
        flex: 0 0 auto;
        position: relative;
        height: toVh(805);
        border-left: 1px solid #787A7C;
        transition: .3s ease;

        @include tablet-1000 {
            width: 350px;
            height: 620px;
        }

        @include mobile-450 {
            width: 300px;
            height: 580px;
        }

        &:hover {
            cursor: url('../img/cases/cursor.jpg') 62 62, pointer;

            img {
                opacity: 1;
            }

            @include tablet-1000 {
                img {
                    opacity: 1;
                }
            }
        }
    }

    &__text {
        position: absolute;
        bottom: 40px;
        left: 70px;
        transform: rotate(-90deg);
        transform-origin: left;
        width: toVh(805);
        z-index: 5;
    }

    &__card-number {
        font-size: 22px;
        line-height: 140%;
        color: #F3F3F3;
    }

    &__card-name {
        font-size: 70px;
        line-height: 110%;
        color: #F3F3F3;

        @media (max-height: 850px) {
            font-size: 56px;
        }

        @include tablet-1000 {
            font-size: 48px;
        }
    }

    &__card-img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
        opacity: 0;
        transition: .3s;

        @include tablet-1000 {
            opacity: 1;
        }
    }
}