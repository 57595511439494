@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Regular.woff') format('woff'),
        url('../fonts/SFProDisplay-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFProDisplay-Light.woff2') format('woff2'),
        url('../fonts/SFProDisplay-Light.woff') format('woff'),
        url('../fonts/SFProDisplay-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}